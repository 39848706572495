
import {
  defineComponent,
} from 'vue';


export default defineComponent({
  name: 'BasePriceText',
  props: {
    modelValue: {
      type: String,
    },
  },
})
