class ConvertNumbers {
  theNumbers: number|string;

  constructor (numbersToConvert) {
    this.theNumbers = numbersToConvert
  }


  /**
   * Trimming the value for only numbers.
   * @params {string}
   * @returns {string} Value without zero number at the start of the string.
   */
  trimSymbols(numberToTrim) {
    return numberToTrim.toString().replace(/\D/g,'')
  }


  /**
   * Removing zero from start of the string.
   * @params {string}
   * @returns {string} Value without zero number at the start of the string.
   */
  trimZeroFromStart(string) {
    return string.replace(/^0+/, '')
  }


  /**
   * Formats numbers to string with the price format.
   * Format mask with the flag: 000 000 000.00
   * Format mask without the flag: 000000000.00
   * @params {boolean} Changes format (with spaces or without)
   * @returns {string} Formatted price value.
  */
  convertToPriceFormat(flag) {
    let integer = ''
    let decimal = ''
    let trimmedValue = null
    let finalPrice = null

    trimmedValue = this.trimSymbols(this.theNumbers.toString())
    trimmedValue = this.trimZeroFromStart(trimmedValue)

    /*
      Dividing integer/decimal depending on value length.
    */
    if (trimmedValue.length > 2) {
      integer = trimmedValue.slice(0, trimmedValue.length - 2)
      decimal = trimmedValue.slice(-2)
    } else if (trimmedValue.length === 2) {
      integer = '0'
      decimal = trimmedValue
    } else {
      integer = '0'
      decimal = '0' + trimmedValue
    }


    /*
      If true - 000 000 000.00
      If false - 000000000.00
    */
    if (flag) {
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    finalPrice = integer + '.' + decimal

    return finalPrice
  }
}

export default ConvertNumbers
