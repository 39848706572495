import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.vueNumberFormat(_ctx.modelValue, {
      decimal: ',',
      thousand: ' ',
      prefix: '',
      suffix: ' ₽',
      masked: false,
      precision: 2,
    })), 1))
}