import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "base-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_pre = _resolveComponent("base-pre")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_pre, {
      hide: "",
      title: "tagsData data",
      data: _ctx.tagsData
    }, null, 8, ["data"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsData, (tag) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: tag,
        closable: "",
        "disable-transitions": false,
        onClose: ($event: any) => (_ctx.handleClose(tag))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(tag), 1)
        ]),
        _: 2
      }, 1032, ["onClose"]))
    }), 128)),
    _createVNode(_component_el_input, {
      class: "input-new-tag",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      ref: "refTagsInput",
      size: "small",
      onKeyup: _withKeys(_ctx.handleInputConfirm, ["enter"]),
      input: "validate"
    }, null, 8, ["modelValue", "onKeyup"]),
    _createVNode(_component_el_button, {
      class: "button-new-tag",
      size: "small",
      onClick: _ctx.handleInputConfirm
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonName), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}