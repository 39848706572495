
import {
  ref,
  provide,
  computed,
  reactive,
  onMounted,
  defineComponent,
} from 'vue'
import ApiService from '@/core/services/ApiService'
import isEmptyObject from '@/core/helpers/isEmptyObject'
import AdsFilter from '@/core/components/filters/AdsFilter.vue'
import InterfaceFilterAdsData from '@/assets/ts/_utils/models/FilterAdsData'
import BasePre from '@/core/components/base/BasePre.vue'
import ConvertDate from '@/core/helpers/convertDate'
import ConvertNumbers from '@/core/helpers/convertNumbers'
import { ElementAnimateUtil } from "@/assets/ts/_utils/ElementAnimateUtil"
import VueEasyLightbox from 'vue-easy-lightbox'
import { truncate } from 'lodash'
import AgroPriceText from '@/core/components/agro/AgroPriceText.vue';

export default defineComponent({
  name: 'AdsList',
  components: {
    BasePre,
    AdsFilter,
    AgroPriceText,
    VueEasyLightbox,
  },
  setup() {
    /**
     * Interface for the single photo.
     */
    interface InterfacePhotoItem {
      id: number
      original_name: string
      path: string
    }

    /**
     * Interface for the source type.
     */
    interface InterfaceSourceType {
      type: string
    }

    /**
     * Interface for the single news object.
     */
    interface InterfaceAdsObject {
      active: boolean
      checked: boolean
      code: string
      country_of_origin: string
      created_at: string
      description: string
      fixed: boolean
      fixed_until: string
      geo_name: string
      id: number
      measure_name: string
      name: string
      owner: {
        id: number
        name: string
        account_pro: boolean
        last_login: string
        last_update: string
        registered_at: string
        stats: {
          ads: {
            active: number
            demand: number
            offer: number
            archive: number
            disabled: number
            rejected: number
            confirmed: number
            spros: number
            offers: number
          }
        }
      }
      photos: Array<{
        id: number
        original_name: string
        path: string
      }>
      previewPhotos: Array<string>
      price: number
      reasons: Array<string>
      rejected_count: number
      rubric: {
        id: number
        name: string
        code: string
        fullPath: string
      }
      rubric_props: Array<{}>
      show_contacts: boolean
      status: string
      type: string
      updated_at: string
      modified_at: string
      url: string
      lightboxVisible: boolean
    }

    const rowClasses = 'd-flex flex-column align-items-start justify-content-start'




    const getFullPathRubric = async rubricId => {

      const parentsArray = await ApiService.get(`/rubric/${rubricId}/parents`)
        .then(response => {
          // console.log('> getFullPathRubric >> response.data', response.data)
          return response.data.data
        })
          .catch(error => {
            console.error('> getFullPathRubric >> error', error)
          })

      let rubricPath = ''

      for (const i in parentsArray) {
        rubricPath += `${parentsArray[i].name}`
        if (+i < parentsArray.length - 1) {
          rubricPath += ' / '
        }
      }

      return rubricPath
    }



    /**
     * This function converts requested data for the table.
     * @param {InterfaceAdsObject[]}
     * @returns {InterfaceAdsObject[]} that number, plus one.
     */
    const convertDataForEdit = async items => {
      const updatedItems = []

      for (const i in items) {
        items[i].previewPhotos = items[i].photos.map(photoItem => photoItem.path)
        items[i].reasons = []
        items[i].checked = false
        items[i].lightboxVisible = false

        if (items[i].rubric && items[i].rubric.id) {
          const fullPath = await getFullPathRubric(items[i].rubric.id)
          items[i].rubric.fullPath = fullPath
        }

        updatedItems.push(items[i])
      }

      return updatedItems
    }

    /**
     * Generate the icon title depends on the status of the news.
     * @params {string}.
     * @returns {string}.
     */
    const generateNewsTitle = status => {
      return status === 'confirmed' ? 'Одобренные'
        : status === 'rejected' ? 'Отклонённые'
          : status === 'not_verified' ? 'Не проверенные'
            : 'Nothing'
    }

    const reasons = ref([])
    const adsLoading = ref(false)
    const checkedModel = ref(false)
    const requestedNewsList = ref([])
    const newsPaginationPagesNumber = ref(0)
    const newsPaginationItemsNumber = ref(0)

    /**
     * Detect whether the table has a content.
     * Needed for the table loader.
     * @returns {Boolean}.
     */
    const tableHasContent = computed(() => {
      return requestedNewsList.value.length > 0 && !adsLoading.value
    })

    /**
     * Detect whether one or more checkboxes are checked.
     * Needed for BULK buttons.
     * @returns {Boolean}.
     */
    const someCheckboxesAreChecked = computed(() => {
      return requestedNewsList.value.some(item => item.checked === true)
    })

    /**
     * This function calculate width of table columns depends on the table loading state.
     * If table is loading then we should collapse columns to 0 width.
     * @param {Number} Width of the column.
     */
    const calcWidth = width => {
      return !adsLoading.value && !requestedNewsList.value.length ? 0 : width
    }


    const filterData = reactive({
      page: null,
      limit: null,
      'filter[status]': null,
      'filter[title]': null,
      'filter[active]': null,
      'filter[rubric]': [],
      'filter[type]': null,
      'filter[user_id]': [],
      'filter[id]': [],
    } as InterfaceFilterAdsData)

    provide('filterData', filterData)

    /**
     * This function make the news array empty.
     */
    const clearTheNewsList = () => {
      requestedNewsList.value = []
    }

    /**
     * This function sets the current page for the pagination.
     * @param {Number} Curent page number.
     */
    const setCurrentPage = val => {
      filterData.page = val
    }

    /**
     * Adds the filter params(converted string) to the window URL.
     * Make a concat of the url's parts.
     * This recreates an URL with new parameters,
     * gotted from the filter(protocol + host + path + params).
     * @param {Object}
     */
    const syncParamsToUrl = params => {
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params
      // This replaces the current URL with the recreated.
      window.history.pushState({ path: newurl }, '', newurl)
    }

    /**
     * Converts filter object to the URL params string.
     * @param {InterfaceFilterAdsData} filter data.
     * @returns {String} concatenated params.
     */
    const convertFilterToParams = (data: InterfaceFilterAdsData) => {
      let paramsAsString = ''
      // Iterate the filter object params.
      for (const [key, value] of Object.entries(data)) {
        // If the iterated item type is not a NUMBER (means is Array or String)
        const valueBoolean =
          value && typeof value !== 'number'
            ? Boolean(value.length)
            : Boolean(value)
        // If the iterated item is not a
        if (valueBoolean) {
          if (!paramsAsString.length) {
            // The 1st element in the params row.
            paramsAsString += key + '=' + value
          } else {
            // The rest elements in the params row.
            paramsAsString += '&' + key + '=' + value
          }
        }
      }
      return paramsAsString
    }

    /**
     * This function requests reasons.
     * @returns {Array} Array of reasons for decline.
     */
    const requestReasons = async () => {
      const reasonsObject = await ApiService.query(
        '/admin/reference/moder-reasons?type=ads',
        {}
      )
        .then(response => {
          return response.data
        })
        .catch(() => {
          console.error('Error to get reasons')
        })

      if (reasonsObject && !isEmptyObject(reasonsObject)) {
        for (const [key, value] of Object.entries(reasonsObject)) {
          reasons.value.push({
            key,
            value,
          })
        }
      } else {
        console.error('Что-то не так с запросом причин')
        // ElMessage.warning('Что-то не так с запросом причин')
      }
    }


    const sortState = ref({
      id: {
        dir: false,
        work: false,
      },
      'created_at': {
        dir: false,
        work: false,
      },
      'modified_at': {
        dir: false,
        work: false,
      },
      price: {
        dir: false,
        work: false,
      },
    })


    const currentSort = ref(null)


    /**
     * Requests a bunch of news.
     * @param {Object} Fitler data properties.
     */
    const requestAds = async () => {
      checkedModel.value = false
      // console.log('!! filterData ::', filterData)
      const params:InterfaceFilterAdsData = { ...filterData }

      if (currentSort.value) {
        params.sort = currentSort.value
      }


      /*
      * Convert status ALL to empty value.
      */
      params['filter[status]'] === 'all'
        ? delete params['filter[status]']
        : true
      // console.log('!! params ::', params)
      clearTheNewsList()
      /*
      * Loading bar.
      */
      adsLoading.value = true
      await ApiService.query('/admin/pubs', { params })
        .then(async response => {
          // console.log('response.data :: requestAds 1 ::', response.data)
          /*
            Set the pagination parameters.
          */
          setCurrentPage(response.data.page)
          newsPaginationPagesNumber.value = response.data.pages
          newsPaginationItemsNumber.value = response.data.total
          /*
            Set items of news item.
          */
          // console.log('requestedNewsList.value 1 ::')
          requestedNewsList.value = await convertDataForEdit(response.data.items)
          // console.log('requestedNewsList.value 2 ::', requestedNewsList.value)
          // requestedNewsList.value = response.data.items
          // console.log('requestedNewsList.value ::', requestedNewsList.value)
          adsLoading.value = false
        })
        .catch(() => {
          console.error('Что-то не так с запросом объявлений')
          // ElMessage.warning('Что-то не так с запросом объявлений')
          adsLoading.value = false
        })
    }

    const currentImageId = ref(0);


    const toggleLightbox = index => {
      requestedNewsList.value[index].lightboxVisible = true;
    };

    const setCurrentImageId = id => {
      currentImageId.value = id;
    };


    const closeLightbox = (index) => {
      requestedNewsList.value[index].lightboxVisible = false;
    };


    /**
     * Column sorting.
     * After changing the direction making request for news.
     */
    const sortTheColumn = (name) => {
      for (const [key, value] of Object.entries(sortState.value)) {
        key === name ? value.work = true : value.work = false
      }
      sortState.value[name].dir = !sortState.value[name].dir
      currentSort.value = sortState.value[name].dir ? name : `-${name}`

      requestAds()
    }


    /**
     * Emitted action from the filter search button.
     * @param {Boolean} Whether to refresh pagination or no.
     */
    const filterButtonClick = pageClear => {
      if (pageClear) {
        filterData.page = 1
      }
      syncParamsToUrl(convertFilterToParams(filterData))
      requestAds()
    }

    /**
     * Detect at least the 1 checked entry.
     */
    const getCheckedEntriesIds = computed(() => {
      const idsArray = []
      requestedNewsList.value.forEach(item => {
        if (item.id && item.checked === true) {
          idsArray.push(item.id)
        }
      })
      return idsArray
    })



    /**
     * Converting the date to the date format.
     * @params {string} Date string.
     * @returns {string} Formatted date string.
     */
    const dateConverter = dateString => {
      const dateConverter = new ConvertDate(dateString as string)
      return dateConverter.convertMe()
    }


    /**
     * Converting numbers to the price format.
     * @params {number} Price numbers.
     * @returns {string} Formatted price string.
     */
    const convertedPrice = numbers => {
      let result = null
      if (numbers) {
        const convertedPrice = new ConvertNumbers(numbers as number)
        result = convertedPrice.convertToPriceFormat(true)
      }
      return result
    }


    /**
     * Mounted hook.
     */
    onMounted(() => {
      requestReasons()
    })

    return {
      isEmptyObject,
      getCheckedEntriesIds,
      rowClasses,
      calcWidth,
      generateNewsTitle,
      // Pagination
      setCurrentPage,
      // News
      requestAds,
      clearTheNewsList,
      convertDataForEdit,
      // Filter options
      filterData,
      // Reasons
      reasons,
      requestReasons,
      // Others
      filterButtonClick,
      checkedModel,
      tableHasContent,
      requestedNewsList,
      adsLoading,
      newsPaginationPagesNumber,
      newsPaginationItemsNumber,
      someCheckboxesAreChecked,
      dateConverter,
      convertedPrice,
      getFullPathRubric,
      sortState,
      sortTheColumn,
      currentSort,
      toggleLightbox,
      closeLightbox,
      setCurrentImageId,
      currentImageId,
    }
  },
  computed: {
    /**
     * Detect reasons of checked entry.
     */
    getReasonsOfCheckedEntries() {
      const entriesObject = {}
      this.requestedNewsList.forEach(item => {
        if (item.id && item.checked === true && item.reasons.length) {
          entriesObject[item.id] = item.reasons.map(item => item.value.id)
        }
      })
      return entriesObject
    },
    /**
     * Finding both: checked items and unchecked reasons for an entry.
     * If at least one of checked items has no reason,
     * then we shouldn't allow the Bulk Declining
     * and should return FALSE
     * Should return TRUE only if all the entries has the selected reason.
     * 'Value 1' - if the item are checked, and the reason are checked.
     * 'Value 2' - if the item are checked, but without the reason.
     * 'Value 3' - if the item are NOT checked, but the reason are checked.
     * We should return FALSE if any of items matched the 'Value 2'.
     * @returns {boolean}.
     */
    detectUncheckedReasonsOnCheckedEntries() {
      const entries = this.requestedNewsList.map(item => {
        if (item.checked && item.reasons.length) {
          return 1
        } else if (item.checked && !item.reasons.length) {
          return 2
        } else if (!item.checked && item.reasons.length) {
          return 3
        }
      })
      const result = entries.some(item => item === 2)
      return result
    },
    /**
     * Title for the decline button.
     * @returns {String}.
     */
    declineButtonTitle() {
      let title = ''
      if (
        !this.someCheckboxesAreChecked &&
        !this.detectUncheckedReasonsOnCheckedEntries
      ) {
        title = 'Выберите хотя бы один элемент из списка'
      } else if (
        this.someCheckboxesAreChecked &&
        this.detectUncheckedReasonsOnCheckedEntries
      ) {
        title = 'Выберите причину отклонения для выбранных элементов'
      } else {
        title = 'Отклонить'
      }
      return title
    },
    /**
     * Detect the DISABLE status of the Bulk Decline button.
     * We should show the Bulk Decline button if:
     * 1. At least 1 entry are checked;
     * 2. Detect entries without the reason (have to be sure that every checked entry has a reason)
     * @returns {Boolean}.
     */
    bulkDeclineStatus() {
      return !this.someCheckboxesAreChecked
        || this.detectUncheckedReasonsOnCheckedEntries
    },
  },
  methods: {
    /**
     * Automatic checkbox selection when selecting the reason checkbox.
     * @param {Number} The news ID
     */
    checkItemOnReasonSelect(index) {
      const clickedNews = this.requestedNewsList[index]
      if (clickedNews.reasons.length) {
        clickedNews.checked = true
      } else {
        clickedNews.checked = false
      }
    },
    switchAllCheckboxes() {
      if (this.checkedModel) {
        this.requestedNewsList.forEach(item => {
          item.checked = false
        })
      } else {
        this.requestedNewsList.forEach(item => {
          item.checked = true
        })
      }
    },
    requestToAllowEntry(item) {
      return ApiService.post(`/admin/pubs/${item.id}/approve`, { id: item.id })
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с одобрением новости',
          })
          console.error('Что-то не так с одобрением новости')
        })
    },
    allowEntry(item) {
      const entry = item
      this.$confirm(
        `Вы действительно хотите одобрить запись<br><strong>${entry.name}</strong> (${entry.id})?`,
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const resultOfAllowEntry = await this.requestToAllowEntry(entry)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (resultOfAllowEntry) {
                this.requestAds()
                instance.confirmButtonLoading = false
                ElementAnimateUtil.scrollTop(0, 500)
                done()
              } else {
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Одобрение подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Одобрение отклонено',
          })
        })
    },
    requestToDeleteEntry(id) {
      const params = {
        ad: id.toString(),
      }
      return ApiService.post(`/admin/pubs/${id}/destroy`, {})
        .then(response => {
          return response.data.result
        })
        .catch(error => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с удалением новости',
          })
          console.error('Что-то не так с удалением новости')
          return error
        })
    },
    deleteEntry(item) {
      this.$confirm(
        `Вы действительно хотите удалить запись<br><strong>${item.name}</strong> (${item.id})?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const response = await this.requestToDeleteEntry(item.code)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (response) {
                instance.confirmButtonLoading = false
                this.requestAds()
                done()
                ElementAnimateUtil.scrollTop(0, 500)
              } else {
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Удаление подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          })
        })
    },
    requestToAllowBulkEntries(ids) {
      const params = {
        ids: ids,
      }
      return ApiService.post('/admin/pubs/bulk-approve', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с одобрением новости',
          })
          console.error('Что-то не так с одобрением новости')
        })
    },
    allowBulkEntries() {
      this.$confirm(
        'Вы действительно хотите одобрить выбранные записи?',
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await this.requestToAllowBulkEntries(
                this.getCheckedEntriesIds
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestAds()
                done()
                ElementAnimateUtil.scrollTop(0, 500)
              } else {
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Одобрение подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Одобрение отменено',
          })
        })
    },
    requestToRejectBulkEntries(reasonsObject) {
      const params = {
        reasons: reasonsObject,
      }
      console.log('params ::', params)
      return ApiService.post('/admin/pubs/bulk-reject', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          console.error('Что-то не так с отклонением объявлений')
        })
    },
    rejectBulkEntries() {
      this.$confirm(
        'Вы действительно хотите отклонить выбранные записи?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отклонить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await this.requestToRejectBulkEntries(
                this.getReasonsOfCheckedEntries
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestAds()
                this.$message({
                  type: 'success',
                  message: 'Объект отклонён',
                })
                ElementAnimateUtil.scrollTop(0, 500)
                done()
              } else {
                this.$message({
                  type: 'warning',
                  message: 'Что-то не так с отклонением объявлений',
                })
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then()
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Отклонение отменено',
            })
          })
    },
    requestToDeleteBulkEntries(ids) {
      const params = {
        ids: ids,
      }
      return ApiService.post('/admin/pubs/bulk-destroy', params)
        .then(response => {
          return response.data.result
        })
          .catch(() => {
            console.error('Что-то не так с массовым удалением объявлений')
          })
    },
    deleteBulkEntries() {
      this.$confirm(
        'Вы действительно хотите удалить выбранные записи?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = this.requestToDeleteBulkEntries(
                this.getCheckedEntriesIds
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestAds()
                this.$message({
                  type: 'success',
                  message: 'Объект удалён',
                })
                done()
              } else {
                this.$message({
                  type: 'warning',
                  message: 'Что-то не так с удалением объявлений',
                })
                done()
              }
            } else {
              this.$message({
                type: 'info',
                message: 'Удаление отменено',
              })
              done()
            }
          },
        }
      )
        .then()
          .catch(() => {
            console.error('Что-то не так с массовым удалением объявлений')
          })
    },
  },
})
