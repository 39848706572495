import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "price" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-lowercase"
}
const _hoisted_4 = {
  key: 1,
  class: "price_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePriceText = _resolveComponent("BasePriceText")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_BasePriceText, { modelValue: _ctx.modelValue }, null, 8, ["modelValue"]),
          (_ctx.measureName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " / " + _toDisplayString(_ctx.measureName), 1))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, " Нет "))
  ]))
}