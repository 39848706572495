
import {
  ref,
  watch,
  nextTick,
  defineComponent,
} from 'vue';
import BasePre from '@/core/components/base/BasePre.vue'

export default defineComponent({
  name: 'base-tags',
  props: {
    buttonName: {
      default: 'Добавить',
      type: String,
      required: false,
    },
    modelValue: Array,
  },
  components: {
    BasePre,
  },
  setup(props, context) {
    const tagsData = ref([])
    const inputValue = ref('')
    const refTagsInput = ref(null)

    /**
     * Deleting the certain tag.
     */
    const handleClose = tag => {
      tagsData.value.splice(tagsData.value.indexOf(tag), 1)
    }

    /**
     * Show input after enter the value.
     * Focus on the input on enter.
     */
    const focusInput = () => {
      refTagsInput.value?.focus()
    }

    /**
     * Confirmation the tags input.
     */
    const handleInputConfirm = () => {
      if (inputValue.value) {
        tagsData.value.push(inputValue.value)
      }
      inputValue.value = ''
      focusInput()
    }

    /**
     * On enter.
     */
    watch(() => props.modelValue, (newValue, oldValue) => {
      tagsData.value = newValue
    })

    /**
     * On output.
     */
    watch(tagsData.value, (newValue, oldValue) => {
      context.emit('update:modelValue', tagsData.value);
    })

    return {
      tagsData,
      inputValue,
      refTagsInput,
      focusInput,
      handleClose,
      handleInputConfirm,
    }
  },
})
