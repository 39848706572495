
import {
  defineComponent,
} from 'vue';
import BasePriceText from '@/core/components/base/BasePriceText.vue';


export default defineComponent({
  name: 'AgroPriceText',
  props: {
    modelValue: {
      type: String,
    },
    measureName: {
      type: String,
      default: null,
    },
  },
  components: {
    BasePriceText,
  },
});
